<script setup>
import { onMounted, ref, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import dayjs from 'dayjs'
import NovedadesProveedoresMxService from '@/apps/pharmasan/compras/logistica/services/novedadesProveedoresMx.service'
import { error, messageConfirmSave } from '../../../../../../../libs/mensajes'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const filteredProveedor = ref([])
const listProveedores = ref([])
const listTiposNovedades = ref([])
const filteredTiposNovedades = ref([])
const _NovedadesProveedoresMxService = ref(new NovedadesProveedoresMxService())
const loadingItems = ref(false)
const medicamentosList = ref([])

// Validar si es edición
const isEdit = computed(() => {
  return !!route.params.id
})

// Obtener los proveedores
const getProveedores = async () => {
  await _NovedadesProveedoresMxService.value.getProveedores().then(({ data }) => {
    listProveedores.value = data
  }).catch((err) => {
    console.error(err.message)
  })
}

/* Funcion para buscar los proveedores */
const searchProveedores = async (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredProveedor.value = [...listProveedores.value]
    } else {
      filteredProveedor.value = listProveedores.value.filter((prov) => {
        // Buscar por bodega o codigo
        return prov.proveedor.toUpperCase().includes(event.query.toUpperCase())
      })
    }
  }, 250)
}

/* Funcion para buscar el Mx */
const searchMx = async ({ query }) => {
  if (!query.trim() || query.length <= 3) {
    medicamentosList.value = []
  } else {
    await _NovedadesProveedoresMxService.value.getAllMds(query.toUpperCase()).then(({ data }) => {
      loadingItems.value = false
      medicamentosList.value = data
    }).catch((err) => {
      console.error(err.message)
    })
  }
}

// Funcion para obtener los tipos de novedades
const getTiposNovedades = async () => {
  await _NovedadesProveedoresMxService.value.getTiposNovedades().then(({ data }) => {
    listTiposNovedades.value = data
  }).catch((err) => {
    console.error(err.message)
  })
}

/* Funcion para buscar los tipos de novedades */
const searchTiposNovedades = async (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredTiposNovedades.value = [...listTiposNovedades.value]
    } else {
      filteredTiposNovedades.value = listTiposNovedades.value.filter((nov) => {
        return nov.name.toUpperCase().includes(event.query.toUpperCase())
      })
    }
  }, 250)
}

// Agregar una fila a la tabla
const addRow = () => {
  model.detailsItems.push({
    linea: model.detailsItems.length + 1,
    item: null,
    cantidad: null,
    novedad: ''
  })
}

// Remover una fila de la tabla de detalles
const removeRow = (data) => {
  // Si solo hay una fila, no se puede eliminar
  if (model.detailsItems.length > 1) {
    const index = model.detailsItems.indexOf(data)
    if (index > -1) {
      model.detailsItems.splice(index, 1)
      // Reordenar las líneas
      model.detailsItems.forEach((item, index) => {
        item.linea = index + 1
      })
    }
  }
}

// Validacione de los campos
const validationSchema = yup.object({
  fecha: yup.string().required('La fecha es requerida').label(''),
  numFactura: yup.string().required('El numero de factura es requerido').label(''),
  // Validar proveedor como objeto
  proveedor: yup.object().nullable().required('El proveedor es requerido').label(''),
  detailsItems: yup
    .array()
    .of(
      yup.object().shape({
        linea: yup.number().required('La linea es requerida').label(''),
        item: yup.object().nullable().required('El medicamento es requerido').label(''),
        cantidad: yup.string().nullable().required('La cantidad es requerida').label(''),
        novedad: yup.object().nullable().required('La novedad es requerida').label('')
      })
    )
})

// Inicializando el formulario
const { errors, handleSubmit, values: model } = useForm({ validationSchema })

// Declarando los campos
useField('fecha', null, { initialValue: dayjs().format('YYYY-MM-DD') })
useField('numFactura', null, { initialValue: '' })
useField('proveedor', null, { initialValue: '' })
useField('comentarios', null, { initialValue: null })
useField('detailsItems', null, {
  initialValue: [
    {
      idDetalle: null,
      linea: 1,
      item: null,
      cantidad: null,
      novedad: ''
    }
  ]
})

// Funcion para guardar la novedad
const onSubmit = handleSubmit(async (values) => {
  // Enviar la confirmacion
  if (isEdit.value) {
    const { id } = route.params

    messageConfirmSave(
      '',
      'Información',
      'warning',
      `¿Esta seguro de editar la novedad #${id}?`
    ).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const dataSend = transformData(model)

        // console.log('DataSend --> ', dataSend)

        _NovedadesProveedoresMxService.value.editarNovedad(id, dataSend).then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'La novedad se ha editado correctamente'
          }).then(() => {
            router.push({ name: 'pharmasan.compras.logistica.novedades-proveedores-mx' })
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    messageConfirmSave(
      '',
      'Información',
      'warning',
      '¿Está seguro que desea guardar la preliminar de la novedad?'
    ).then(async (result) => {
      if (result.isConfirmed) {
        const dataSend = transformData(values)

        await _NovedadesProveedoresMxService.value.saveNovedades(dataSend).then(({ data }) => {
          if (data.result) {
            Swal.fire({
              icon: 'success',
              title: 'Exito',
              text: `La preliminar de la novedad #${data.result.id} se ha creado correctamente`
            }).then(() => {
              // Redireccionar a la lista de novedades
              router.push({ name: 'pharmasan.compras.logistica.novedades-proveedores-mx' })
            })
          }
        }).catch((err) => {
          console.error(err.message)
        })
      }
    }).catch((err) => {
      console.error(err.message)
      if (err.response.status) {
        error(err.response.data.message)
      }
    })
  }
})

// Funcion para transformar los datos
const transformData = (values) => {
  return {
    fecha: values.fecha,
    numFactura: values.numFactura,
    cardCode: values.proveedor.CardCode,
    cardName: values.proveedor.CardName,
    comentarios: values.comentarios,
    detailsItems: values.detailsItems.map((item) => {
      return {
        // Si viene el idDetalle es porque es una edición
        idDetalle: item.idDetalle ?? null,
        linea: item.linea,
        itemCode: item.item.ItemCode,
        itemName: item.item.ItemName,
        cantidad: item.cantidad,
        idTipoNovedad: item.novedad.id,
        tipoNovedad: item.novedad.name
      }
    })
  }
}

// Obtener la información de la novedad a editar
const getNovedad = async (id) => {
  await _NovedadesProveedoresMxService.value.getNovedad(id).then(({ status, data: response }) => {
    if (status !== 200) return

    // Si el valor es null mostrar swal de error y redireccionar al listado
    if (response.length <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La novedad no existe'
      }).then(() => {
        router.push({ name: 'pharmasan.compras.logistica.novedades-proveedores-mx.listado' })
      })
    } else {
      model.fecha = response.fecha
      model.numFactura = response.numFactura
      model.proveedor = {
        CardCode: response.cardCode,
        CardName: response.cardName,
        proveedor: response.proveedor
      }
      model.comentarios = response.comentarios
      model.detailsItems = response.detailsItems.map((item) => {
        return {
          idDetalle: item.idDetalle ?? null,
          linea: item.linea,
          item: {
            ItemCode: item.itemCode,
            ItemName: item.itemName,
            nombreCompleto: item.itemCode + ' - ' + item.itemName
          },
          cantidad: item.cantidad,
          novedad: {
            id: item.idTipoNovedad,
            name: item.tipoNovedad
          }
        }
      })
    }
  }).catch((err) => {
    console.error(err.message)
  })
}

// Hook
onMounted(async () => {
  await getProveedores()
  await getTiposNovedades()

  if (isEdit.value) {
    const { id } = route.params

    await getNovedad(id)
  }
})
</script>

<template>
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <!-- Titulo de la card -->
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-lg mr-auto" :class="isEdit ? 'text-yellow-600' : 'text-green-800'">{{ isEdit ? 'Editar Novedad Proveedor Mx' : 'Nueva Novedad Proveedor Mx' }}</h2>
        </div>

        <!-- Body de la card -->
        <div class="p-4">
          <!-- Encabezado novedades -->
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 0px 0 20px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-base font-semibold">Información General</span>
          </Divider>
          <div class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
            <!-- Fecha -->
            <div class="sm:col-span-3">
              <label for="fecha" class="block text-xs font-medium text-gray-700 mb-2">Fecha:</label>
              <InputText
                id="fecha"
                type="date"
                :max="model.fecha"
                class="w-full"
                :class="{ 'p-invalid': errors.fecha }"
                v-model="model.fecha"
                :pt="{
                  root: { style: 'height: 37px; font-size: 0.75rem' }
                }"
              />
              <MessageError :text="errors.fecha" />
            </div>
            <!-- Numero de la factura -->
            <div class="sm:col-span-3">
              <label for="numFactura" class="block text-xs font-medium text-gray-700 mb-2"># Factura:</label>
              <InputText
                id="numFactura"
                type="text"
                class="w-full uppercase"
                :class="{ 'p-invalid': errors.numFactura }"
                v-model="model.numFactura"
                :pt="{
                  root: { style: 'height: 37px; font-size: 0.75rem' }
                }"
              />
              <MessageError :text="errors.numFactura" />
            </div>
            <!-- Proveedor -->
            <div class="sm:col-span-6">
              <label for="proveedor" class="block text-xs font-medium text-gray-700 mb-2">Proveedor:</label>
              <AutoComplete
                id="proveedor"
                v-model="model.proveedor"
                :suggestions="filteredProveedor"
                @complete="searchProveedores"
                optionLabel="proveedor"
                optionValue="CardCode"
                minLength="1"
                emptySearchMessage="No se encontraron resultados"
                forceSelection
                dropdown
                :loading="loadingItems"
                placeholder="Digite al menos 1 caracter para buscar el proveedor..."
                class="text-xs w-full"
                :class="{ 'p-invalid': errors.proveedor }"
                inputClass="w-full"
                :pt="{
                  root: { style: 'height: 37px' },
                  input: { style: 'font-size: 0.75rem' },
                  panel: { style: 'font-size: 0.75rem' }
                }"
              />
              <MessageError :text="errors.proveedor" />
            </div>

            <!-- Comentarios -->
            <div class="sm:col-span-6">
              <label for="comentarios" class="block text-xs font-medium text-gray-700 mb-2">Comentarios:</label>
              <Textarea
                id="comentarios"
                class="text-xs w-full"
                :autoResize="true"
                v-model="model.comentarios"
                rows="3"
                cols="20"
              />
            </div>

          </div>

          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 20px 0 20px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-base font-semibold">Detalle Articulos</span>
          </Divider>

          <!-- Detalle de la novedades -->
          <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-4">
            <div class="col-span-1 sm:col-span-12 md:col-span-12">
              <DataTable
                ref="dt"
                :value="model.detailsItems"
                tableStyle="min-width: 50rem"
                class="p-datatable-sm text-xs"
                stripedRows
                dataKey="id"
                showGridlines
              >
                <template #empty> No hay datos para mostrar. </template>
                <template #loading> Cargando la información, por favor espere... </template>
                <Column
                  headerStyle="width: 1rem; text-align: center"
                  bodyStyle="text-align: center; overflow: visible"
                >
                  <template #body="{ data }">
                    <Button
                      type="button"
                      class="p-button-danger p-button-xs"
                      icon="pi pi-trash"
                      v-tooltip.top="'Eliminar fila'"
                      @click="removeRow(data)"
                    />
                  </template>
                </Column>
                <Column
                  field="item"
                  header="Descripción Articulo"
                  style="min-width:32rem"
                >
                  <template #body="{ data }">
                    <AutoComplete
                      v-model="data.item"
                      :suggestions="medicamentosList"
                      @complete="searchMx"
                      optionLabel="nombreCompleto"
                      minLength="4"
                      emptySearchMessage="No se encontraron resultados"
                      forceSelection
                      :loading="loadingItems"
                      placeholder="Digite al menos 4 caracteres para buscar un Medicamento..."
                      :class="{ 'p-invalid': errors[`detailsItems[${data.linea - 1}].item`] }"
                      class="text-xs w-full"
                      inputClass="w-full"
                      :pt="{
                        root: { style: 'height: 1.7rem' },
                        input: { style: 'font-size: 0.75rem' },
                        panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                        item: { style: 'word-wrap: break-word;' }
                      }"
                    />
                    <MessageError :text="errors[`detailsItems[${data.linea - 1}].item`]" />
                  </template>
                </Column>
                <Column
                  field="cantidad"
                  header="Cantidad"
                  style="max-width:5rem"
                >
                  <template #body="{data}">
                    <InputText
                      id="cantidad"
                      type="number"
                      :min="1"
                      class="text-xs w-full"
                      placeholder="Ingresa la cantidad..."
                      v-model="data.cantidad"
                      :class="{ 'p-invalid': errors[`detailsItems[${data.linea - 1}].cantidad`] }"
                      :pt="{
                        root: ({
                          props, state, context
                        }) => ({
                          style: 'height: 1.7rem; font-size: 0.75rem;'
                        })
                      }"
                    />
                    <MessageError :text="errors[`detailsItems[${data.linea - 1}].cantidad`]" />
                  </template>
                </Column>
                <Column
                  field="novedad"
                  header="Novedad"
                >
                  <template #body="{data}">
                    <AutoComplete
                      v-model="data.novedad"
                      :suggestions="filteredTiposNovedades"
                      @complete="searchTiposNovedades"
                      optionLabel="name"
                      optionValue="id"
                      minLength="1"
                      emptySearchMessage="No se encontraron resultados"
                      forceSelection
                      dropdown
                      :loading="loadingItems"
                      placeholder="Digite al menos 1 caracter para buscar la novedad..."
                      class="text-xs w-full"
                      :class="{ 'p-invalid': errors[`detailsItems[${data.linea - 1}].novedad`] }"
                      inputClass="w-full"
                      :pt="{
                        root: { style: 'height: 1.7rem' },
                        input: { style: 'font-size: 0.75rem' },
                        panel: { style: 'font-size: 0.75rem' }
                      }"
                    />
                    <MessageError :text="errors[`detailsItems[${data.linea - 1}].novedad`]" />
                  </template>
                </Column>
              </DataTable>

              <div class="grid grid-cols-6 gap-4 mt-4">
                <Button
                  label="Agregar Articulo"
                  icon="pi pi-plus"
                  class="p-button-sm col-span-6 sm:col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1"
                  @click="addRow"
                  v-tooltip.top="'Agregar Articulo'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Button
      :icon="isEdit ? 'pi pi-pencil' : 'pi pi-send'"
      :label="isEdit ? 'Editar' : 'Guardar'"
      class="floating-button"
      :severity="isEdit ? 'warning' : 'success'"
      @click="onSubmit"
    />
  </div>
</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 10050;
}
</style>
